<template>
    <div>
        <div class="flex flex-row justify-content-between align-items-center">
            <b-tabs pills v-model="tab" class="border-2">
                <b-tab title="مرحلة التعليم الجامعي" active> </b-tab>
                <b-tab title="مرحلة التعليم المدرسي"> </b-tab>
            </b-tabs>
            <div class="w-[50%]">
                <div class="flex" style="margin-top: -18px">
                    <ek-dialog
                        v-if="tab == 0"
                        ref="newdialog"
                        title="استيراد دورات التعليم الجامعي"
                        size="md"
                        btnText="استيراد دورات التعليم الجامعي "
                        @ok="ok()"
                    >
                        <template #body>
                            <validationObserver ref="newdialogForm">
                                <ek-input-image
                                    class="mt-1"
                                    ref="imgFile"
                                    label=" الملف  "
                                    name="الملف "
                                    :accept="'.xlsx, .xls, .csv'"

                                    @input="storeExcelFile($event)"
                                >
                                    <h5>انقر لتحميل الملف</h5>
                                </ek-input-image>
                                <ek-input-select
                                    v-model="SubjectId"
                                    label="المادة"
                                    placeholder="اختر المادة "
                                    :options="subjectsNames"
                                    name="select"
                                    :clearable="true"
                                />
                            </validationObserver>
                        </template>
                    </ek-dialog>
                    <ek-dialog
                        v-if="tab == 1"
                        ref="newExceldialog"
                        title="استيراد دورات التعليم الاساسي"
                        size="md"
                        btnText="استيراد دورات التعليم الاساسي "
                        @ok="AddExcel()"
                    >
                        <template #body>
                            <validationObserver ref="newdialogFormForExcel">
                                <ek-input-image
                                    class="mt-1"
                                    ref="imgFile"
                                    label=" الملف  "
                                    name="الملف "
                                    :accept="'.xlsx, .xls, .csv'"

                                    @input="storeExcelFile($event)"
                                >
                                    <h5>انقر لتحميل الملف</h5>
                                </ek-input-image>
                                <ek-input-select
                                    v-model="SubjectId"
                                    label="المادة"
                                    placeholder="اختر المادة "
                                    :options="subjectsNames"
                                    name="select"
                                    :clearable="true"
                                />
                            </validationObserver>
                        </template>
                    </ek-dialog>
                </div>
            </div>
        </div>
        <ek-table
            :columns="columnsExam"
            :items="previousExamsList.previousExams"
            @delete-selected="deleteExam"
        >
            <template slot="items.isFree" slot-scope="{ value }">
                <span
                    class="rounded text-white"
                    :class="value == true ? 'bg-primary' : 'bg-danger'"
                    style="padding: 5px"
                >
                    {{ value ? "مجاني" : "مدفوع" }}</span
                >
            </template>
            <template slot="items.id" slot-scope="{ value }">
                <b-button
                    size="sm"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    style="padding: 2px 6px !important"
                    @click="previousExamsDetails(value)"
                >
                    <unicon name="ellipsis-v" width="18"></unicon>
                </b-button>
            </template>
        </ek-table>
    </div>
</template>

<script>
// import axiosIns from "@/libs/axios";
import { mapActions, mapGetters, mapState } from "vuex";
// import "vue-good-table/dist/vue-good-table.css";
export default {
    data() {
        return {
            tab: 0,

            lessonsTableCols: [
                {
                    label: "الاسم ",
                    field: "order",
                },
                {
                    label: "الحالة ",
                    field: "order",
                },
            ],
            subjectId: "",
            filterDto: {
                PreviousExamType: "PreviousExam",
                subjectId: null,
                StudentType: this.tab,
            },
        };
    },
    computed: {
        ...mapState({
            previousExamsList: (state) => state.previousExams.previousExamsList,
            columns: (state) => state.previousExams.columns,
            hello: (state) => state.previousExams.hello,
            subjectsNames: ({ subjects }) => subjects.subjectsNames,

        }),
        ...mapGetters(["allBros"]),
    },
    methods: {
        ...mapActions([
            "getPreviousExams",
            "getSubjectsNames",
            "getUniversityList",
            "getchapterList",
            "getFacultyList",
            "AddFromExcel",

        ]),
        previousExamsDetails(id) {
            console.log("fdf", id);
            this.$router.push(`/admin/previousExams/${id}`);
        },
        deleteExam(e) {
            // this.$store.dispatch("deletechapter", e).finally(() => {
            //     this.getchapterList({
            //         ...this.filterDto,
            //         studentType: this.tab,
            //     });
            // });
            console.log(e);
        },
        storeExcelFile(event) {
            console.log(event);
            if (event && event.file) {
                this.file = event.file;
            }
        },
        ok() {
            this.$refs.newdialogForm.validate().then((success) => {
                if (success) {
                    console.log(this.file, this.SubjectId);
                    this.AddFromExcel({
                        PreviousExamFile: this.file,
                        SubjectId: this.SubjectId,
                        previousExamType: "PreviousExam"

                    });
                }
            });
        },
        AddExcel() {
            this.$refs.newdialogFormForExcel.validate().then((success) => {
                if (success) {
                    console.log(this.file, this.SubjectId);
                    this.AddFromSchoolExcel({
                        PreviousExamFile: this.file,
                        
                        SubjectId: this.SubjectId,
                        previousExamType: "PreviousExam"

                    });
                }
            });
        },
    },
    created() {
        this.getPreviousExams({
            ...this.filterDto,
            PreviousExamType: "PreviousExam",
            StudentType: 0,
        });
        this.getUniversityList(0);

        this.getSubjectsNames(0);
        this.getchapterList(0);
        this.getFacultyList(0);
    },
    watch: {
        tab: function (newTab) {
            if (newTab == 0) {
                console.log(newTab, "dddddddddd");

                this.getPreviousExams({
                    ...this.filterDto,
                    PreviousExamType: "PreviousExam",
                    StudentType: 0,
                });
                this.getSubjectsNames(0);
                this.getUniversityList(0);
                this.getchapterList(0);
                this.getFacultyList(0);
            } else {
                this.getPreviousExams({
                    ...this.filterDto,
                    PreviousExamType: "PreviousExam",
                    StudentType: 1,
                });
                this.getSubjectsNames(1);
                this.getUniversityList(1);
                this.getchapterList(1);
                this.getFacultyList(1);
            }
            this.$router.push({ query: { tab: newTab } });
        },
    },
    mounted() {
        if (this.$route.query.tab !== String(this.tab)) {
            this.$router.replace({ query: { tab: this.tab } });
        }
    },
};
</script>
